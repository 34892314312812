import type { Project } from '~/models/project.server';
import type { User } from '~/models/user.server';
import { useState, useEffect } from 'react';
import type { InputHTMLAttributes } from 'react';

export const MEMBER_ACCESS_LEVEL = 20;
export const ADMIN_ACCESS_LEVEL = 30;

export function isBoostedProject(project: Project) {
  return (project.allFeatureNames || []).includes(
    'pufferfish_boosted_collection',
  );
}

export function userIsProjectAdmin({
  project,
  user,
}: {
  project: Project;
  user: User;
}) {
  if (!user || !project) return false;
  return project?.permissions?.some(
    ({ userId, accessLevel }) =>
      user.id === userId && accessLevel >= ADMIN_ACCESS_LEVEL,
  );
}

export function userIsProjectMember({
  project,
  user,
}: {
  project: Project;
  user: User;
}) {
  if (!user || !project) return false;
  return project?.permissions?.some(
    ({ userId, accessLevel }) =>
      user.id === userId && accessLevel >= MEMBER_ACCESS_LEVEL,
  );
}

type DebouncedInputProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: DebouncedInputProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export function pluralize(
  word: string,
  count: number,
  alternatePlural?: string,
) {
  if (count == 1) {
    return word;
  } else if (alternatePlural) {
    return alternatePlural;
  } else {
    return `${word}s`;
  }
}
